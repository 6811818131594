import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  varlicense$,
  vartranslations$,
} from 'src/app/core/services/startup.service';
import {
  ContextMenuAction,
  MyDateAdapterOptions,
  MyDateLocale,
  SideBarAction,
  TopLayoutComponent,
} from 'processdelight-angular-components';
import { Subject, filter, first, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CoreModule } from './core.module';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ProjectRoutes } from './core/enums/project-routes.enum';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule, CommonModule, CoreModule, TopLayoutComponent],
  providers: [
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: MyDateAdapterOptions },
    {
      provide: MAT_DATE_LOCALE,
      useValue: MyDateLocale(varlicense$.value?.language || 'en'),
    },
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'IshtarProjects';

  orgLogo$ = varlicense$.pipe(map((o) => o?.logo));
  appLogo = environment.cdnAppIconUrl.replace('color', 'Blauw_Wit');

  userName$ = varlicense$.pipe(map((u) => u?.name));
  userPhoto$ = varlicense$.pipe(map((u) => u?.photo));

  navColor$ = varlicense$.pipe(map((u) => u?.navColor));
  navContrast$ = varlicense$.pipe(map((u) => u?.navContrast));
  translations = vartranslations$.value;
  license = varlicense$.value;
  destroy$ = new Subject<void>();

  sideBarActions: SideBarAction[] = [];
  iconActions: ContextMenuAction<unknown>[] = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    vartranslations$
      .pipe(
        filter((t) => Object.keys(t).length > 0),
        first()
      )
      .subscribe((t) => {
        varlicense$
          .pipe(
            filter((l) => !!l && !!Object.keys(l).length),
            first()
          )
          .subscribe((l) => {
            l?.licenses.some(
              (i) => i.productName == 'Ishtar.Projects' && i.isAdmin
            )
              ? this.iconActions.push(
                  new ContextMenuAction({
                    label: t?.settings,
                    icon: 'settings',
                    iconOutline: true,
                    action: () =>
                      this.router.navigate([
                        ProjectRoutes.SETTINGS,
                        ProjectRoutes.GENERAL_SETTINGS
                      ]),
                  })
                )
              : undefined;
            document.documentElement.style.setProperty(
              '--nav-color',
              l?.navColor ?? '#fff'
            );
            document.documentElement.style.setProperty(
              '--nav-contrast',
              l?.navContrast ?? '#000'
            );
          });
          this.sideBarActions.push(
            new SideBarAction({
              title: 'Overview',
              icon: 'overview',
              iconOutline: true,
              isSymbol: true,
              route: ['projects'],
            }),
          )
      });
    const params = new URLSearchParams(location.search);
    if (params.has('appColor'))
      this.appLogo = environment.cdnAppIconUrl.replace(
        'color',
        params.get('appColor')!
      );
    const tabIconLink = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement;
    if (tabIconLink) tabIconLink.href = this.appLogo;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
