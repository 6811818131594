import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarProjectService } from '../../services/project.service';
import {
  addProjectEstimations,
  addProjectEstimationsResolved,
  getProjectEstimations,
  getProjectEstimationsResolved,
  removeProjectEstimations,
  removeProjectEstimationsResolved,
  updateProjectEstimations,
  updateProjectEstimationsResolved,
} from './projectEstimation.actions';

@Injectable({ providedIn: 'root' })
export class ProjectEstimationEffects {
  constructor(
    private actions$: Actions,
    private ishtarProjectService: IshtarProjectService
  ) {}

  getProjectEstimations = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectEstimations),
      switchMap(({ filters, callback }) =>
        this.ishtarProjectService.getProjectEstimations(filters).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((projectEstimations) =>
            of(
              getProjectEstimationsResolved({
                projectEstimations,
              })
            )
          ),
          catchError((e) => [])
        )
      )
    )
  );

  addProjectEstimations = createEffect(() =>
    this.actions$.pipe(
      ofType(addProjectEstimations),
      switchMap(({ projectEstimations, callback, error }) =>
        this.ishtarProjectService
          .addProjectEstimations(projectEstimations)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((addedProjectEstimations) =>
              of(addProjectEstimationsResolved({ addedProjectEstimations }))
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  updateProjectEstimations = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProjectEstimations),
      switchMap(({ projectEstimations, callback, error }) =>
        this.ishtarProjectService
          .updateProjectEstimations(projectEstimations)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((updatedProjectEstimations) =>
              of(
                updateProjectEstimationsResolved({ updatedProjectEstimations })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  removeProjectEstimations = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProjectEstimations),
      switchMap(({ ids, callback, error }) =>
        this.ishtarProjectService.removeProjectEstimations(ids).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((ids) =>
            of(removeProjectEstimationsResolved({ ids }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
