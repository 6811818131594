export enum ProjectRoutes {
    PROJECTS = 'projects',
    SETTINGS = 'settings',
    SKILLS = 'skills',
    PRODUCTS = 'products',
    DEFAULT_PRICES = 'default-prices',
    PROJECT_TYPES = 'project-types',
    BUDGET_LIBRARY = 'budget-library',
    GENERAL_SETTINGS = 'general-settings',
    TEMPLATES = 'templates',
}