import { createAction, props } from '@ngrx/store';
import { Rate } from '../../models/project/rate';

export const getDefaultRates = createAction(
  '[Rates] Get default rates',
  props<{
    callback?: () => void;
  }>()
);

export const getDefaultRatesResolved = createAction(
  '[Rates] Get default rates resolved',
  props<{
    rates: Rate[];
  }>()
);

export const removeDefaultRate = createAction(
  '[Rates] Remove default rates',
  props<{ ids: string[]; callback?: () => void }>()
);

export const removeDefaultRateResolved = createAction(
  '[Rates] Remove default rates resolved',
  props<{ ids: string[] }>()
);

export const updateDefaultRates = createAction(
  '[Rates] Update default rates',
  props<{ rates: Rate[]; callback?: () => void }>()
);

export const updateDefaultRatesResolved = createAction(
  '[Rates] Update default rates resolved',
  props<{ updatedDefaultRates: Rate[] }>()
);

export const addDefaultRates = createAction(
  '[Rates] Add default rates',
  props<{ rates: Rate[]; callback?: (rates: Rate[]) => void }>()
);

export const addDefaultRatesResolved = createAction(
  '[Rates] Add default rates resolved',
  props<{ addedDefaultRates: Rate[] }>()
);
