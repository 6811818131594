import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { productState } from './product.reducer';
import {
  getProducts,
  addProducts,
  removeProduct,
  updateProducts,
  getQuantityUnits,
  getLinkedQuantityUnits,
  addQuantityUnits,
  updateQuantityUnits,
  removeQuantityUnit,
} from './product.actions';
import { Product } from '../../models/project/product';
import { QuantityUnit } from '../../models/project/quantityUnit';
import { BaseFacade } from '../base.facade';

@Injectable({
  providedIn: 'root',
})
export class ProductFacade extends BaseFacade {
  products$ = this._store.pipe(
    select(productState),
    map((state) => state.products)
  );
  quantityUnits$ = this._store.pipe(
    select(productState),
    map((state) => state.quantityUnits)
  );
  linkedUnits$ = this._store.pipe(
    select(productState),
    map((state) => state.linkedUnits)
  );

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getProducts$() {
    return this.dispatchAction$(getProducts);
  }

  addProducts(products: Product[]) {
    return this.dispatchAction$(addProducts, { products });
  }

  removeProduct(productId: string) {
    return this.dispatchAction$(removeProduct, { productId });
  }

  updateProducts(products: Product[]) {
    return this.dispatchAction$(updateProducts, { products });
  }

  getQuantityUnits$() {
    return this.dispatchAction$(getQuantityUnits);
  }

  getLinkedQuantityUnits$() {
    return this.dispatchAction$(getLinkedQuantityUnits);
  }

  addQuantityUnits(quantityUnits: QuantityUnit[]) {
    return this.dispatchAction$(addQuantityUnits, { quantityUnits });
  }

  updatedQuantityUnits(quantityUnits: QuantityUnit[]) {
    return this.dispatchAction$(updateQuantityUnits, { quantityUnits });
  }

  removeQuantityUnits(ids: string[]) {
    return this.dispatchAction$(removeQuantityUnit, { ids });
  }
}
