import { createAction, props } from '@ngrx/store';
import { Project } from '../../models/project/project';
import { Filter } from 'processdelight-angular-components';
import { ActionBase } from '../action-base.interface';

export const getProjects = createAction(
  '[Project] Get projects',
  props<{
    orderBy: string;
    direction: string;
    filters: Filter[];
    pageSize: number;
    page: number;
    resetPaging: boolean;
    callback?: (projects: Project[]) => void;
    errorCallback?: (error: any) => void;
  }>()
);

export const getProjectsResolved = createAction(
  '[Project] Get projects resolved',
  props<{
    projects: Project[];
    totalRecordCount: number;
    resetPaging: boolean;
  }>()
);

export const getProject = createAction(
  '[Project] Get project',
  props<{
    projectId: string;
    callback?: (project: Project) => void;
  }>()
);

export const getProjectResolved = createAction(
  '[Project] Get project resolved',
  props<{
    project: Project;
  }>()
);

export const getProjectTemplatesResolved = createAction(
  '[Project] Get project templates resolved',
  props<{
    projects: Project[];
  }>()
);

export const removeProject = createAction(
  '[Project] Remove project',
  props<
    { projectId: string } & ActionBase<{
      deletedProjectId: string;
      deletedDiscountIds: string[] | undefined;
    }>
  >()
);

export const removeProjectResolved = createAction(
  '[Project] Remove project resolved',
  props<{ ids: string[] }>()
);

export const updateProject = createAction(
  '[Project] Update project',
  props<
    {
      project: Project;
      createProjectChannel: boolean | undefined;
    } & ActionBase<Project>
  >()
);

export const updateProjectResolved = createAction(
  '[Project] Update project resolved',
  props<{ project: Project }>()
);

export const addProject = createAction(
  '[Project] Add project',
  props<
    {
      project: Project;
      createProjectChannel: boolean | undefined;
      createdFromTemplate: boolean | undefined;
    } & ActionBase<Project>
  >()
);

export const addProjectResolved = createAction(
  '[Project] Add project resolved',
  props<{ addedProject: Project }>()
);

export const addProjectTemplateResolved = createAction(
  '[Project] Add project template resolved',
  props<{
    project: Project;
  }>()
);
