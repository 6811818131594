import { Routes } from "@angular/router";
import { map } from "rxjs";
import { MicrosoftAuthenticationGuard } from "processdelight-angular-components";
import { vartranslations$ } from 'src/app/core/services/startup.service';
import { PreventNavigationGuard } from "./core/guards/prevent-navigation.guard";
import { ProjectRoutes } from "./core/enums/project-routes.enum";

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('processdelight-angular-components').then((m) => m.MsalModule),
  },
  {
    path: ProjectRoutes.PROJECTS,
    canActivate: [MicrosoftAuthenticationGuard],
    canDeactivate: [PreventNavigationGuard],
    loadComponent: () =>
      import('./project-dashboard/project-dashboard.component').then(
        (mod) => mod.ProjectDashboardComponent
      ),
    data: { breadcrumbTitle: vartranslations$.pipe(map((u) => u?.overview)) },
  },
  {
    path: ProjectRoutes.SETTINGS,
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('./settings/settings.component').then(
        (mod) => mod.SettingsComponent
      ),
    data: { breadcrumbTitle: 'Settings' },
    children: [
      {
        path: ProjectRoutes.SKILLS,
        loadComponent: () =>
          import('./settings/skills/skills.component').then(
            (mod) => mod.SkillsComponent
          ),
        data: { breadcrumbTitle: 'Skills' },
      },
      {
        path: ProjectRoutes.PRODUCTS,
        loadComponent: () =>
          import('./settings/products/products.component').then(
            (mod) => mod.ProductsComponent
          ),
        data: { breadcrumbTitle: 'Products' },
      },
      {
        path: ProjectRoutes.DEFAULT_PRICES,
        loadComponent: () =>
          import('./settings/prices/prices.component').then(
            (mod) => mod.PricesComponent
          ),
        data: { breadcrumbTitle: 'Default prices' },
        canDeactivate: [PreventNavigationGuard],
      },
      {
        path: ProjectRoutes.PROJECT_TYPES,
        loadComponent: () =>
          import('./settings/project-types/project-types.component').then(
            (mod) => mod.ProjectTypesComponent
          ),
        data: { breadcrumbTitle: 'Project types' },
        canDeactivate: [PreventNavigationGuard],
      },
      {
        path: ProjectRoutes.BUDGET_LIBRARY,
        loadComponent: () =>
          import('./settings/budget-library/budget-library.component').then(
            (mod) => mod.BudgetLibraryComponent
          ),
        data: { breadcrumbTitle: 'Budget library' },
        canDeactivate: [PreventNavigationGuard],
      },
      {
        path: ProjectRoutes.GENERAL_SETTINGS,
        loadComponent: () =>
          import('./settings/general/general.component').then(
            (mod) => mod.GeneralComponent
          ),
        data: { breadcrumbTitle: 'General settings' },
      },
      {
        path: ProjectRoutes.TEMPLATES,
        loadComponent: () =>
          import('./settings/templates/templates.component').then(
            (mod) => mod.TemplatesComponent
          ),
        data: { breadcrumbTitle: 'Templates' },
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
