import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarProjectService } from '../../services/project.service';
import {
  addProjectTypeLines,
  addProjectTypeLinesResolved,
  getProjectTypeLines,
  getProjectTypeLinesResolved,
  removeProjectTypeLines,
  removeProjectTypeLinesResolved,
  updateProjectTypeLines,
  updateProjectTypeLinesResolved,
} from './project-type-line.actions';

@Injectable({ providedIn: 'root' })
export class ProjectTypeLineEffects {
  constructor(
    private actions$: Actions,
    private ishtarProjectService: IshtarProjectService
  ) {}

  getProjectTypeLines = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectTypeLines),
      switchMap(({ callback }) =>
        this.ishtarProjectService.getProjectTypeLines().pipe(
          switchMap((projectTypeLines) =>
            of(
              getProjectTypeLinesResolved({
                projectTypeLines,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addProjectTypeLines = createEffect(() =>
    this.actions$.pipe(
      ofType(addProjectTypeLines),
      switchMap(({ projectTypeLines }) =>
        this.ishtarProjectService.addProjectTypeLines(projectTypeLines).pipe(
          switchMap((addedProjectTypeLines) =>
            of(addProjectTypeLinesResolved({ addedProjectTypeLines }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  updateProjectTypeLines = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProjectTypeLines),
      switchMap(({ projectTypeLines }) =>
        this.ishtarProjectService.updateProjectTypeLines(projectTypeLines).pipe(
          switchMap((updatedProjectTypeLines) =>
            of(updateProjectTypeLinesResolved({ updatedProjectTypeLines }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  removeProjectTypeLines = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProjectTypeLines),
      switchMap(({ ids, callback }) =>
        this.ishtarProjectService.removeProjectTypeLines(ids).pipe(
          switchMap((ids) =>
            of(removeProjectTypeLinesResolved({ ids }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );
}
