export function camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,
          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: camelcaseKeys(
            e[1]
          ),
        }),
        {}
      );
    else return obj;
  }
  
  export function capitalizeKeys(obj: any, ...ignoredProperties: string[]): any {
    const ignoredPropertiesLower = ignoredProperties.map((p) =>
      p.toLowerCase()
    );
  
    if (Array.isArray(obj))
      return [...obj.map((o) => capitalizeKeys(o, ...ignoredProperties))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,
  
          [e[0].charAt(0).toUpperCase() + e[0].slice(1)]:
            ignoredPropertiesLower.includes(e[0].toLowerCase())
              ? e[1]
              : capitalizeKeys(e[1], ...ignoredProperties),
        }),
  
        {}
      );
    else return obj;
  }