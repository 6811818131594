import { BaseEntity } from 'processdelight-angular-components';

export class ProjectEstimationParamFixedPercent extends BaseEntity {
  title!: string;
  isLineSpecific?: boolean;
  amount?: number;
  estimationParamId!: string;

  constructor(obj: Partial<ProjectEstimationParamFixedPercent>) {
    super(obj);
    Object.assign(this, obj);
  }
}
