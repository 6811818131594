import { BaseEntity } from 'processdelight-angular-components';

export class ProjectMVPBlockTemplateLine extends BaseEntity {
  title!: string;
  description?: string;
  amount?: number;
  rowNr?: number;
  required?: boolean;
  skillId?: string;
  productId?: string;
  mvpBlockTemplateId!: string;
  productUnitId?: string;
  skillUnit?: string;

  constructor(obj: Partial<ProjectMVPBlockTemplateLine>) {
    super(obj);
    Object.assign(this, obj);
  }
}
