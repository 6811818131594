import { BaseEntity } from 'processdelight-angular-components';

export class Rate extends BaseEntity {
  title!: string;
  skillId?: string;
  productId?: string;
  projectId?: string;
  hourlyRate?: number;
  fixedPercentage?: number;
  quantityUnitId?: string;

  constructor(obj: Partial<Rate>) {
    super(obj);
    Object.assign(this, obj);
  }
}
