import { BaseEntity } from 'processdelight-angular-components';

export class Client extends BaseEntity {
  name!: string;
  teamId?: string;
  isDeleted!: boolean;

  constructor(obj: Partial<Client>) {
    super(obj);
    Object.assign(this, obj);
  }
}
