import { createAction, props } from '@ngrx/store';
import { ProjectEstimationParamFixedPercent } from '../../models/project/projectEstimationParamFixedPercent';

export const getProjectEstimationParamFixedPercent = createAction(
  '[ProjectEstimationParamFixedPercent] Get projectEstimationParamFixedPercent',
  props<{
    callback?: () => void;
  }>()
);

export const getProjectEstimationParamFixedPercentResolved = createAction(
  '[ProjectEstimationParamFixedPercent] Get projectEstimationParamFixedPercent resolved',
  props<{
    projectEstimationParamFixedPercent: ProjectEstimationParamFixedPercent[];
  }>()
);

export const removeProjectEstimationParamFixedPercent = createAction(
  '[ProjectEstimationParamFixedPercent] Remove projectEstimationParamFixedPercent',
  props<{ ids: string[]; callback?: () => void }>()
);

export const removeProjectEstimationParamFixedPercentResolved = createAction(
  '[ProjectEstimationParamFixedPercent] Remove projectEstimationParamFixedPercent resolved',
  props<{ ids: string[] }>()
);

export const updateProjectEstimationParamFixedPercent = createAction(
  '[ProjectEstimationParamFixedPercent] Update projectEstimationParamFixedPercent',
  props<{
    projectEstimationParamFixedPercent: ProjectEstimationParamFixedPercent[];
    callback?: () => void;
  }>()
);

export const updateProjectEstimationParamFixedPercentResolved = createAction(
  '[ProjectEstimationParamFixedPercent] Update projectEstimationParamFixedPercent resolved',
  props<{
    updatedProjectEstimationParamFixedPercent: ProjectEstimationParamFixedPercent[];
  }>()
);

export const addProjectEstimationParamFixedPercent = createAction(
  '[ProjectEstimationParamFixedPercent] Add projectEstimationParamFixedPercent',
  props<{
    projectEstimationParamFixedPercent: ProjectEstimationParamFixedPercent[];
  }>()
);

export const addProjectEstimationParamFixedPercentResolved = createAction(
  '[ProjectEstimationParamFixedPercent] Add projectEstimationParamFixedPercent resolved',
  props<{
    addedProjectEstimationParamFixedPercent: ProjectEstimationParamFixedPercent[];
  }>()
);

export const estimationParamDeletedActionResolved = createAction(
  '[ProjectEstimationParamFixedPercent] Estimation param deleted action resolved',
  props<{
    deletedEstimationParamId: string;
  }>()
);
