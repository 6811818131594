import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import { ProjectTypeLine } from '../../models/project/projectTypeLine';
import {
  getProjectTypeLinesResolved,
  removeProjectTypeLinesResolved,
  updateProjectTypeLinesResolved,
  addProjectTypeLinesResolved,
  projectTypeDeleteActionResolved,
} from './project-type-line.actions';

export const featureSlice = 'projectTypeLine';

export interface State {
  projectTypeLines?: ProjectTypeLine[];
  projectTypeLine?: ProjectTypeLine;
  ids?: string[];
}
const defaultState: State = {
  projectTypeLines: undefined,
  projectTypeLine: undefined,
  ids: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return projectTypeLineReducer(state, action);
}

export const initialState: State = defaultState;

export const projectTypeLineReducer = createReducer(
  initialState,
  on(getProjectTypeLinesResolved, (state, { projectTypeLines }) => ({
    ...state,
    projectTypeLines: [...projectTypeLines],
  })),
  on(removeProjectTypeLinesResolved, (state, { ids }) => ({
    ...state,
    projectTypeLines: state.projectTypeLines?.filter(
      (p) => !ids.includes(p.id!)
    ),
  })),
  on(updateProjectTypeLinesResolved, (state, { updatedProjectTypeLines }) => ({
    ...state,
    projectTypeLines: state.projectTypeLines?.map(
      (p) => updatedProjectTypeLines.find((u) => p.id === u.id) ?? p
    ),
  })),
  on(addProjectTypeLinesResolved, (state, { addedProjectTypeLines }) => ({
    ...state,
    projectTypeLines: addedProjectTypeLines.concat([
      ...(state.projectTypeLines ?? []),
    ]),
  })),
  on(projectTypeDeleteActionResolved, (state, { deletedProjectTypeId }) => ({
    ...state,
    projectTypeLines: state.projectTypeLines?.filter(
      (p) => p.projectTypeId !== deletedProjectTypeId
    ),
  }))
);

export const projectTypeLineState = (state: AppState) =>
  state.coreFeature.projectTypeLine;
