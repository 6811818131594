import { BaseEntity } from 'processdelight-angular-components';

export class ProjectType extends BaseEntity {
  title!: string;
  hasEstimation?: boolean;
  estimationParamId?: string;

  constructor(obj: Partial<ProjectType>) {
    super(obj);
    Object.assign(this, obj);
  }
}
