import { BaseEntity } from 'processdelight-angular-components';

export class QuantityUnit extends BaseEntity {
  title!: string;

  constructor(obj: Partial<QuantityUnit>) {
    super(obj);
    Object.assign(this, obj);
  }
}
