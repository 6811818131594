import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarProjectService } from '../../services/project.service';
import {
  addProjectMVPBlockTemplates,
  addProjectMVPBlockTemplatesResolved,
  getProjectMVPBlockTemplates,
  getProjectMVPBlockTemplatesResolved,
  removeProjectMVPBlockTemplates,
  removeProjectMVPBlockTemplatesResolved,
  updateProjectMVPBlockTemplates,
  updateProjectMVPBlockTemplatesResolved,
} from './projectMVPBlockTemplate.actions';

@Injectable({ providedIn: 'root' })
export class ProjectMVPBlockTemplateEffects {
  constructor(
    private actions$: Actions,
    private ishtarProjectService: IshtarProjectService
  ) {}

  getProjectMVPBlockTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectMVPBlockTemplates),
      switchMap(({ callback }) =>
        this.ishtarProjectService.getProjectMVPBlockTemplates().pipe(
          switchMap((projectMVPBlockTemplates) =>
            of(
              getProjectMVPBlockTemplatesResolved({
                projectMVPBlockTemplates,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addProjectMVPBlockTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType(addProjectMVPBlockTemplates),
      switchMap(({ projectMVPBlockTemplates, callback, error }) =>
        this.ishtarProjectService
          .addProjectMVPBlockTemplates(projectMVPBlockTemplates)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((addedProjectMVPBlockTemplates) =>
              of(
                addProjectMVPBlockTemplatesResolved({
                  addedProjectMVPBlockTemplates,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  updateProjectMVPBlockTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProjectMVPBlockTemplates),
      switchMap(({ projectMVPBlockTemplates, callback, error }) =>
        this.ishtarProjectService
          .updateProjectMVPBlockTemplates(projectMVPBlockTemplates)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((updatedProjectMVPBlockTemplates) =>
              of(
                updateProjectMVPBlockTemplatesResolved({
                  updatedProjectMVPBlockTemplates,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  removeProjectMVPBlockTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProjectMVPBlockTemplates),
      switchMap(({ ids, callback, error }) =>
        this.ishtarProjectService.removeProjectMVPBlockTemplates(ids).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((ids) =>
            of(
              removeProjectMVPBlockTemplatesResolved({
                ids,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
