import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarProjectService } from '../../services/project.service';
import {
  removeQuantityUnit,
  removeQuantityUnitResolved,
  getQuantityUnits,
  getQuantityUnitsResolved,
  getLinkedQuantityUnits,
  getLinkedQuantityUnitsResolved,
  addQuantityUnits,
  addQuantityUnitsResolved,
  updateQuantityUnits,
  updateQuantityUnitsResolved,
} from './product.actions';
import {
  addProducts,
  addProductsResolved,
  getProducts,
  getProductsResolved,
  removeProduct,
  removeProductResolved,
  updateProducts,
  updateProductsResolved,
} from './product.actions';
import { removeProjectTypeLinesResolved } from '../project-type-line/project-type-line.actions';
import { removeProjectMVPBlockTemplateLinesResolved } from '../projectMVPBlockTemplateLine/projectMVPBlockTemplateLine.actions';

@Injectable({ providedIn: 'root' })
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private ishtarProjectService: IshtarProjectService
  ) {}

  getProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(getProducts),
      switchMap(({ callback, error }) =>
        this.ishtarProjectService.getProducts().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((products) =>
            of(
              getProductsResolved({
                products,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  addProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(addProducts),
      switchMap(({ products, callback, error }) =>
        this.ishtarProjectService.addProducts(products).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedProducts) =>
            of(addProductsResolved({ addedProducts }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProducts),
      switchMap(({ products, callback, error }) =>
        this.ishtarProjectService.updateProducts(products).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedProducts) =>
            of(updateProductsResolved({ updatedProducts }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeProduct = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProduct),
      switchMap(({ productId, callback, error }) =>
        this.ishtarProjectService.removeProduct(productId).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((result) => {
            const actions: any[] = [];
            if (result.deletedProjectTypeLineIds) {
              actions.push(
                removeProjectTypeLinesResolved({
                  ids: result.deletedProjectTypeLineIds,
                })
              );
            }
            if (result.deletedProjectMVPBlockTemplateLineIds) {
              actions.push(
                removeProjectMVPBlockTemplateLinesResolved({
                  ids: result.deletedProjectMVPBlockTemplateLineIds,
                })
              );
            }
            actions.push(
              removeProductResolved({
                id: result.deletedProductId,
              })
            );
            return actions;
          }),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getQuantityUnits = createEffect(() =>
    this.actions$.pipe(
      ofType(getQuantityUnits),
      switchMap(({ callback, error }) =>
        this.ishtarProjectService.getQuantityUnits().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((quantityUnits) =>
            of(
              getQuantityUnitsResolved({
                quantityUnits: quantityUnits,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getLinkedQuantityUnits = createEffect(() => 
    this.actions$.pipe(
      ofType(getLinkedQuantityUnits),
      switchMap(({ callback, error }) => 
        this.ishtarProjectService.getLinkedQuantityUnits().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((linkedUnits) =>
            of(getLinkedQuantityUnitsResolved({ linkedUnits }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      ) 
    )
  );

  addQuantityUnits = createEffect(() =>
    this.actions$.pipe(
      ofType(addQuantityUnits),
      switchMap(({ quantityUnits, callback, error }) =>
        this.ishtarProjectService.addQuantityUnits(quantityUnits).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedQuantityUnits) =>
            of(
              addQuantityUnitsResolved({
                addedQuantityUnits: addedQuantityUnits,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateQuantityUnits = createEffect(() =>
    this.actions$.pipe(
      ofType(updateQuantityUnits),
      switchMap(({ quantityUnits, callback, error }) =>
        this.ishtarProjectService.updateQuantityUnits(quantityUnits).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedQuantityUnits) =>
            of(updateQuantityUnitsResolved({ updatedQuantityUnits }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeQuantityUnits = createEffect(() =>
    this.actions$.pipe(
      ofType(removeQuantityUnit),
      switchMap(({ ids, callback, error }) =>
        this.ishtarProjectService
          .removeQuantityUnits(ids)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((ids) =>
              of(removeQuantityUnitResolved({ ids }))
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );
}
