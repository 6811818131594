import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import { ProjectEstimationParam } from '../../models/project/projectEstimationParam';
import {
  getProjectEstimationParamsResolved,
  removeProjectEstimationParamsResolved,
  updateProjectEstimationParamsResolved,
  addProjectEstimationParamsResolved,
} from './projectEstimationParam.actions';

export const featureSlice = 'projectEstimationParam';

export interface State {
  projectEstimationParams?: ProjectEstimationParam[];
  projectEstimationParam?: ProjectEstimationParam;
  ids?: string[];
}
const defaultState: State = {
  projectEstimationParams: undefined,
  projectEstimationParam: undefined,
  ids: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return projectEstimationParamReducer(state, action);
}

export const initialState: State = defaultState;

export const projectEstimationParamReducer = createReducer(
  initialState,
  on(
    getProjectEstimationParamsResolved,
    (state, { projectEstimationParams }) => ({
      ...state,
      projectEstimationParams: [...projectEstimationParams],
    })
  ),
  on(
    removeProjectEstimationParamsResolved,
    (state, { ids }) => ({
      ...state,
      projectEstimationParams: state.projectEstimationParams?.filter(
        (p) => !ids.includes(p.id!)
      ),
    })
  ),
  on(
    updateProjectEstimationParamsResolved,
    (state, { updatedProjectEstimationParams }) => ({
      ...state,
      projectEstimationParams: state.projectEstimationParams?.map(
        (p) => updatedProjectEstimationParams.find((u) => p.id === u.id) ?? p
      ),
    })
  ),
  on(
    addProjectEstimationParamsResolved,
    (state, { addedProjectEstimationParams }) => ({
      ...state,
      projectEstimationParams: addedProjectEstimationParams.concat([
        ...(state.projectEstimationParams ?? []),
      ]),
    })
  )
);

export const projectEstimationParamState = (state: AppState) =>
  state.coreFeature.projectEstimationParam;
