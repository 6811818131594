import { BaseEntity } from 'processdelight-angular-components';
import { StatusType } from './statusType';

export class Status extends BaseEntity {
  status!: string;
  color?: string;
  isFinalState?: boolean;
  types?: StatusType[];

  constructor(obj: Partial<Status>) {
    super(obj);
    Object.assign(this, obj);
  }
}
