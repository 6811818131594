import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarProjectService } from '../../services/project.service';
import {
  addProjectEstimationParams,
  addProjectEstimationParamsResolved,
  getProjectEstimationParams,
  getProjectEstimationParamsResolved,
  removeProjectEstimationParams,
  removeProjectEstimationParamsResolved,
  updateProjectEstimationParams,
  updateProjectEstimationParamsResolved,
} from './projectEstimationParam.actions';
import { estimationParamDeletedActionResolved } from '../projectEstimationParamFixedPercent/projectEstimationParamFixedPercent.actions';

@Injectable({ providedIn: 'root' })
export class ProjectEstimationParamEffects {
  constructor(
    private actions$: Actions,
    private ishtarProjectService: IshtarProjectService
  ) {}

  getProjectEstimationParams = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectEstimationParams),
      switchMap(({ callback }) =>
        this.ishtarProjectService.getProjectEstimationParams().pipe(
          switchMap((projectEstimationParams) =>
            of(
              getProjectEstimationParamsResolved({
                projectEstimationParams,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addProjectEstimationParams = createEffect(() =>
    this.actions$.pipe(
      ofType(addProjectEstimationParams),
      switchMap(({ projectEstimationParams, callback }) =>
        this.ishtarProjectService
          .addProjectEstimationParams(projectEstimationParams)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((addedProjectEstimationParams) =>
              of(
                addProjectEstimationParamsResolved({
                  addedProjectEstimationParams,
                })
              )
            ),
            catchError((e) => [])
          )
      )
    )
  );

  updateProjectEstimationParams = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProjectEstimationParams),
      switchMap(({ projectEstimationParams }) =>
        this.ishtarProjectService
          .updateProjectEstimationParams(projectEstimationParams)
          .pipe(
            switchMap((updatedProjectEstimationParams) =>
              of(
                updateProjectEstimationParamsResolved({
                  updatedProjectEstimationParams,
                })
              )
            ),
            catchError((e) => [])
          )
      )
    )
  );

  removeProjectEstimationParams = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProjectEstimationParams),
      switchMap(({ ids, callback }) =>
        this.ishtarProjectService.removeProjectEstimationParams(ids).pipe(
          switchMap((ids) => [
            removeProjectEstimationParamsResolved({
              ids,
            }),
            estimationParamDeletedActionResolved({
              deletedEstimationParamId: ids[0],
            }),
          ]),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );
}
