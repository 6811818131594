import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarProjectService } from '../../services/project.service';
import {
  addProjectEstimationParamFixedPercent,
  addProjectEstimationParamFixedPercentResolved,
  getProjectEstimationParamFixedPercent,
  getProjectEstimationParamFixedPercentResolved,
  removeProjectEstimationParamFixedPercent,
  removeProjectEstimationParamFixedPercentResolved,
  updateProjectEstimationParamFixedPercent,
  updateProjectEstimationParamFixedPercentResolved,
} from './projectEstimationParamFixedPercent.actions';

@Injectable({ providedIn: 'root' })
export class ProjectEstimationParamFixedPercentEffects {
  constructor(
    private actions$: Actions,
    private ishtarProjectService: IshtarProjectService
  ) {}

  getProjectEstimationParamFixedPercent = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectEstimationParamFixedPercent),
      switchMap(({ callback }) =>
        this.ishtarProjectService.getProjectEstimationParamFixedPercent().pipe(
          switchMap((projectEstimationParamFixedPercent) =>
            of(
              getProjectEstimationParamFixedPercentResolved({
                projectEstimationParamFixedPercent,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addProjectEstimationParamFixedPercent = createEffect(() =>
    this.actions$.pipe(
      ofType(addProjectEstimationParamFixedPercent),
      switchMap(({ projectEstimationParamFixedPercent }) =>
        this.ishtarProjectService
          .addProjectEstimationParamFixedPercent(
            projectEstimationParamFixedPercent
          )
          .pipe(
            switchMap((addedProjectEstimationParamFixedPercent) =>
              of(
                addProjectEstimationParamFixedPercentResolved({
                  addedProjectEstimationParamFixedPercent,
                })
              )
            ),
            catchError((e) => [])
          )
      )
    )
  );

  updateProjectEstimationParamFixedPercent = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProjectEstimationParamFixedPercent),
      switchMap(({ projectEstimationParamFixedPercent }) =>
        this.ishtarProjectService
          .updateProjectEstimationParamFixedPercent(
            projectEstimationParamFixedPercent
          )
          .pipe(
            switchMap((updatedProjectEstimationParamFixedPercent) =>
              of(
                updateProjectEstimationParamFixedPercentResolved({
                  updatedProjectEstimationParamFixedPercent,
                })
              )
            ),
            catchError((e) => [])
          )
      )
    )
  );

  removeProjectEstimationParamFixedPercent = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProjectEstimationParamFixedPercent),
      switchMap(({ ids, callback }) =>
        this.ishtarProjectService
          .removeProjectEstimationParamFixedPercent(ids)
          .pipe(
            switchMap((ids) =>
              of(
                removeProjectEstimationParamFixedPercentResolved({
                  ids,
                })
              )
            ),
            tap(() => (callback ? callback() : undefined)),
            catchError((e) => [])
          )
      )
    )
  );
}
