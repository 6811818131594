import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserLicenseInfo } from '../models/app/userlicenseinfo';
import { Project } from '../models/project/project';
import { map, tap } from 'rxjs';
import { Status } from '../models/project/status';
import { Skill } from '../models/project/skill';
import { Product } from '../models/project/product';
import { Rate } from '../models/project/rate';
import { ProjectType } from '../models/project/projectType';
import { Client } from '../models/project/client';
import { ProjectMVPBlockTemplate } from '../models/project/projectMVPBlockTemplate';
import { ProjectMVPBlockTemplateLine } from '../models/project/projectMVPBlockTemplateLine';
import { ProjectTypeLine } from '../models/project/projectTypeLine';
import { ProjectEstimationParam } from '../models/project/projectEstimationParam';
import { ProjectEstimationParamFixedPercent } from '../models/project/projectEstimationParamFixedPercent';
import { ProjectEstimation } from '../models/project/projectEstimation';
import { ProjectMVP } from '../models/project/projectMVP';
import { ProjectMVPLine } from '../models/project/projectMVPLine';
import { Discount } from '../models/project/discount';
import { QuantityUnit } from '../models/project/quantityUnit';
import { Config } from '../models/project/config';
import { AppInfo } from '../models/app/appInfo';
import { FunctionsService } from './functions.service';
import { camelcaseKeys } from '../helpers/object.functions';
import { DashboardComponent, Filter, GroupUser } from 'processdelight-angular-components';

@Injectable({
  providedIn: 'root',
})
export class IshtarProjectService {
  private apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient,
    private functionsService: FunctionsService
  ) {}

  getLicense(tenantId: string) {
    return this.httpClient.post<UserLicenseInfo>(
      `${this.apiBase}/session/register?tenantId=${tenantId}`,
      {}
    );
  }

  sessionKeepAlive() {
    return this.httpClient.post(`${this.apiBase}/session/keepalive`, {});
  }

  getAppInfo(app: string) {
    return this.httpClient
      .get<AppInfo>(`${this.apiBase}/organization/app/${app}`)
      .pipe(map((info) => new AppInfo(camelcaseKeys(info))));
  }

  getStartUpData(userId: string, userEmail: string, language: string) {
    return this.httpClient.get<any>(`${this.apiBase}/project/start-up?userId=${userId}&userEmail=${userEmail}&language=${language}`);
  }

  getProjects(    
    orderBy: string,
    direction: string,
    filters: Filter[],
    pageSize: number,
    page: number
  ) {
    const filter = DashboardComponent.createFilterString(filters);
    let url = `${this.apiBase}/project?orderBy=${orderBy}&direction=${direction}&pageSize=${pageSize}&page=${page}`;

    if (filter !== '') {
      url += `&filter=${filter}`;
    }

    return this.httpClient.get<{ result: Project[]; totalRecordCount: number; }>(url);
  }

  addProject(project: Project, createProjectChannel?: boolean, createdFromTemplate?: boolean) {
    return this.httpClient.post<Project>(`${this.apiBase}/project?createChannel=${createProjectChannel ?? false}&createdFromTemplate=${createdFromTemplate ?? false}`, project)
      .pipe(map((p) => new Project(camelcaseKeys(p))));
  }

  removeProject(projectId: string) {
    return this.httpClient.delete<{ deletedProjectId: string; deletedDiscountIds: string[] | undefined; }>(`${this.apiBase}/project/${projectId}`)
      .pipe(map((result) => ({
        deletedProjectId: result.deletedProjectId,
        deletedDiscountIds: result.deletedDiscountIds,
      })));
  }

  updateProject(project: Project, createProjectChannel?: boolean) {
    return this.httpClient.patch<Project>(`${this.apiBase}/project?createChannel=${createProjectChannel ?? false}`, project)
      .pipe(map((p) => new Project(camelcaseKeys(p))));
  }

  getProject(projectId: string) {
    return this.httpClient.get<Project>(`${this.apiBase}/project/${projectId}`)
      .pipe(map((p) => new Project(camelcaseKeys(p))));
  }  

  getSkills() {
    return this.httpClient.get<Skill[]>(`${this.apiBase}/skill`)
      .pipe(map((skills) => skills.map((s) => new Skill(camelcaseKeys(s)))));
  }  

  getLatestProjectId() {
    return this.httpClient.get<number>(`${this.apiBase}/project/latest`);
  }  

  getProjectMVPsByProjectId(projectId: string) {
    return this.httpClient.get<{ projectMVPs: ProjectMVP[]; projectMVPLines: ProjectMVPLine[]; }>(`${this.apiBase}/project/${projectId}/mvp`)
      .pipe(map((result) => ({
        projectMVPs: result.projectMVPs.map((p) => new ProjectMVP(camelcaseKeys(p))),
        projectMVPLines: result.projectMVPLines.map((p) => new ProjectMVPLine(camelcaseKeys(p)))
      })));
  }  

  updateSkills(skills: Skill[]) {
    return this.httpClient.patch<Skill[]>(`${this.apiBase}/skill`, skills)
      .pipe(map((skills) => skills.map((s) => new Skill(camelcaseKeys(s)))));
  }

  removeSkill(skillId: string) {
    return this.httpClient.delete<{ deletedProjectTypeLineIds: string[]; deletedProjectMVPBlockTemplateLineIds: string[]; deletedSkillId: string; }>(`${this.apiBase}/skill/${skillId}`)
      .pipe(map((result) => ({
        deletedProjectTypeLineIds: result.deletedProjectTypeLineIds,
        deletedProjectMVPBlockTemplateLineIds: result.deletedProjectMVPBlockTemplateLineIds,
        deletedSkillId: result.deletedSkillId,
      })));
  }

  addSkills(skills: Skill[]) {
    return this.httpClient.post<Skill[]>(`${this.apiBase}/skill`, skills)
      .pipe(map((skills) => skills.map((s) => new Skill(camelcaseKeys(s)))));
  }

  getProducts() {
    return this.httpClient.get<Product[]>(`${this.apiBase}/product`)
      .pipe(map((products) => products.map((p) => new Product(camelcaseKeys(p)))));
  }  

  updateProducts(products: Product[]) {
    return this.httpClient.patch<Product[]>(`${this.apiBase}/product`, products)
      .pipe(map((products) => products.map((p) => new Product(camelcaseKeys(p)))));
  }

  removeProduct(productId: string) {
    return this.httpClient.delete<{ deletedProjectTypeLineIds: string[]; deletedProjectMVPBlockTemplateLineIds: string[]; deletedProductId: string; }>(`${this.apiBase}/product/${productId}`)
      .pipe(map((result) => ({
          deletedProjectTypeLineIds: result.deletedProjectTypeLineIds,
          deletedProjectMVPBlockTemplateLineIds:
            result.deletedProjectMVPBlockTemplateLineIds,
          deletedProductId: result.deletedProductId,
      })));
  }

  addProducts(products: Product[]) {
    return this.httpClient.post<Product[]>(`${this.apiBase}/product`, products)
      .pipe(map((products) => products.map((p) => new Product(camelcaseKeys(p)))));
  }

  getDefaultRates() {
    return this.httpClient.get<Rate[]>(`${this.apiBase}/rate?filter=(Title eq DefaultRate)`)
      .pipe(map((rates) => rates.map((r) => new Rate(camelcaseKeys(r)))));
  }

  addDefaultRates(rates: Rate[]) {
    return this.httpClient.post<Rate[]>(`${this.apiBase}/rate`, rates)
      .pipe(map((rates) => rates.map((r) => new Rate(camelcaseKeys(r)))));
  }

  updateDefaultRates(rates: Rate[]) {
    return this.httpClient.patch<Rate[]>(`${this.apiBase}/rate`, rates)
      .pipe(map((rates) => rates.map((r) => new Rate(camelcaseKeys(r)))));
  }

  removeDefaultRate(rateIds: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/rate`, { body: rateIds });
  }

  getProjectTypes() {
    return this.httpClient.get<ProjectType[]>(`${this.apiBase}/project-type`)
      .pipe(map((types) => types.map((t) => new ProjectType(camelcaseKeys(t)))));
  }

  addProjectTypes(projectTypes: ProjectType[]) {
    return this.httpClient.post<ProjectType[]>(`${this.apiBase}/project-type`, projectTypes)
      .pipe(map((types) => types.map((t) => new ProjectType(camelcaseKeys(t)))));
  }

  updateProjectTypes(projectTypes: ProjectType[]) {
    return this.httpClient.patch<ProjectType[]>(`${this.apiBase}/project-type`, projectTypes)
      .pipe(map((types) => types.map((t) => new ProjectType(camelcaseKeys(t)))));
  }

  removeProjectType(projectTypeId: string) {
    return this.httpClient.delete<{ deletedProjectTypeId: string; deletedEstimationParamId: string | undefined; }>(`${this.apiBase}/project-type/${projectTypeId}`)
      .pipe(map((result) => ({
        deletedProjectTypeId: result.deletedProjectTypeId,
        deletedEstimationParamId: result.deletedEstimationParamId,
      })));
  }
  
  getProjectTypeLines() {
    return this.httpClient.get<ProjectTypeLine[]>(`${this.apiBase}/project-type-line`)
      .pipe(map((lines) => lines.map((l) => new ProjectTypeLine(camelcaseKeys(l)))));
  }

  addProjectTypeLines(projectTypesLines: ProjectTypeLine[]) {
    return this.httpClient.post<ProjectTypeLine[]>(`${this.apiBase}/project-type-line`, projectTypesLines)
      .pipe(map((lines) => lines.map((l) => new ProjectTypeLine(camelcaseKeys(l)))));
  }

  updateProjectTypeLines(projectTypesLines: ProjectTypeLine[]) {
    return this.httpClient.patch<ProjectTypeLine[]>(`${this.apiBase}/project-type-line`, projectTypesLines)
      .pipe(map((lines) => lines.map((l) => new ProjectTypeLine(camelcaseKeys(l)))));
  }

  removeProjectTypeLines(ids: string []) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/project-type-line`, { body: ids });
  }

  getProjectEstimationParams() {
    return this.httpClient.get<ProjectEstimationParam[]>(`${this.apiBase}/project-estimation-param`)
      .pipe(map((estimationParams) => estimationParams.map((estimationParam) => new ProjectEstimationParam(camelcaseKeys(estimationParam)))));
  }

  addProjectEstimationParams(projectEstimationParams: ProjectEstimationParam[]) {
    return this.httpClient.post<ProjectEstimationParam[]>(`${this.apiBase}/project-estimation-param`, projectEstimationParams)
      .pipe(map((result) => result.map((estimationParam) => new ProjectEstimationParam(camelcaseKeys(estimationParam)))));
  }

  updateProjectEstimationParams(projectEstimationParams: ProjectEstimationParam[]) {
    return this.httpClient.patch<ProjectEstimationParam[]>(`${this.apiBase}/project-estimation-param`, projectEstimationParams)
      .pipe(map((result) => result.map((estimationParam) => new ProjectEstimationParam(camelcaseKeys(estimationParam)))));
  }
  
  removeProjectEstimationParams(ids: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/project-estimation-param`, { body: ids });
  }

  getProjectEstimationParamFixedPercent() {
    return this.httpClient.get<ProjectEstimationParamFixedPercent[]>(`${this.apiBase}/project-estimation-param-fixed-percent`)
      .pipe(map((result) => result.map((e) => new ProjectEstimationParamFixedPercent(camelcaseKeys(e)))));
  }

  addProjectEstimationParamFixedPercent(projectEstimationParamFixedPercent: ProjectEstimationParamFixedPercent[]) {
    return this.httpClient.post<ProjectEstimationParamFixedPercent[]>(`${this.apiBase}/project-estimation-param-fixed-percent`, projectEstimationParamFixedPercent)
      .pipe(map((result) => result.map((e) => new ProjectEstimationParamFixedPercent(camelcaseKeys(e)))));
  }

  updateProjectEstimationParamFixedPercent(projectEstimationParamFixedPercent: ProjectEstimationParamFixedPercent[]) {
    return this.httpClient.post<ProjectEstimationParamFixedPercent[]>(`${this.apiBase}/project-estimation-param-fixed-percent`, projectEstimationParamFixedPercent)
      .pipe(map((result) => result.map((e) => new ProjectEstimationParamFixedPercent(camelcaseKeys(e)))));
  }

  removeProjectEstimationParamFixedPercent(ids: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/project-estimation-param-fixed-percent`, { body: ids });
  }

  getProjectMVPBlockTemplates() {
    return this.httpClient.get<ProjectMVPBlockTemplate[]>(`${this.apiBase}/project-mvp-block-template`)
      .pipe(map((result) => result.map((t) => new ProjectMVPBlockTemplate(camelcaseKeys(t)))));
  }

  addProjectMVPBlockTemplates(projectMVPBlockTemplates: ProjectMVPBlockTemplate[]) {
    return this.httpClient.post<ProjectMVPBlockTemplate[]>(`${this.apiBase}/project-mvp-block-template`, projectMVPBlockTemplates)
      .pipe(map((result) => result.map((t) => new ProjectMVPBlockTemplate(camelcaseKeys(t)))));
  }

  updateProjectMVPBlockTemplates(projectMVPBlockTemplates: ProjectMVPBlockTemplate[]) {
    return this.httpClient.patch<ProjectMVPBlockTemplate[]>(`${this.apiBase}/project-mvp-block-template`, projectMVPBlockTemplates)
      .pipe(map((result) => result.map((t) => new ProjectMVPBlockTemplate(camelcaseKeys(t)))));
  }

  removeProjectMVPBlockTemplates(ids: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/project-mvp-block-template`, { body: ids });
  }

  getProjectMVPBlockTemplateLines() {
    return this.httpClient.get<ProjectMVPBlockTemplateLine[]>(`${this.apiBase}/project-mvp-block-template-line`)
      .pipe(map((result) => result.map((tl) => new ProjectMVPBlockTemplateLine(camelcaseKeys(tl)))));
  }

  addProjectMVPBlockTemplateLines(projectMVPBlockTemplateLines: ProjectMVPBlockTemplateLine[]) {
    return this.httpClient.post<ProjectMVPBlockTemplateLine[]>(`${this.apiBase}/project-mvp-block-template-line`, projectMVPBlockTemplateLines)
      .pipe(map((result) => result.map((tl) => new ProjectMVPBlockTemplateLine(camelcaseKeys(tl)))));
  }

  updateProjectMVPBlockTemplateLines(projectMVPBlockTemplateLines: ProjectMVPBlockTemplateLine[]) {
    return this.httpClient.patch<ProjectMVPBlockTemplateLine[]>(`${this.apiBase}/project-mvp-block-template-line`, projectMVPBlockTemplateLines)
      .pipe(map((result) => result.map((tl) => new ProjectMVPBlockTemplateLine(camelcaseKeys(tl)))));
  }

  removeProjectMVPBlockTemplateLines(ids: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/project-mvp-block-template-line`, { body: ids });
  }

  getProjectMVPs(filters: Filter[]) {
    let url = `${this.apiBase}/project-mvp`;
    const filter = DashboardComponent.createFilterString(filters);

    if (filter !== '') {
      url += `?filter=${filter}`;
    }

    return this.httpClient.get<ProjectMVP[]>(url)
      .pipe(map((result) => result.map((mvp) => new ProjectMVP(camelcaseKeys(mvp)))));
  }

  addProjectMVPs(projectMVPs: ProjectMVP[]) {
    return this.httpClient.post<ProjectMVP[]>(`${this.apiBase}/project-mvp`, projectMVPs)
      .pipe(map((result) => result.map((mvp) => new ProjectMVP(camelcaseKeys(mvp)))));
  }

  updateProjectMVPs(projectMVPs: ProjectMVP[]) {
    return this.httpClient.patch<ProjectMVP[]>(`${this.apiBase}/project-mvp`, projectMVPs)
      .pipe(map((result) => result.map((mvp) => new ProjectMVP(camelcaseKeys(mvp)))));
  }

  removeProjectMVPs(ids: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/project-mvp`, { body: ids });
  }

  getProjectMVPLines(filters: Filter[]) {
    let url = `${this.apiBase}/project-mvp-line`;
    const filter = DashboardComponent.createFilterString(filters);

    if (filter !== '') {
      url += `?filter=${filter}`;
    }

    return this.httpClient.get<ProjectMVPLine[]>(url)
      .pipe(map((result) => result.map((mvp) => new ProjectMVPLine(camelcaseKeys(mvp)))));
  }

  addProjectMVPLines(projectMVPLines: ProjectMVPLine[]) {
    return this.httpClient.post<ProjectMVPLine[]>(`${this.apiBase}/project-mvp-line`, projectMVPLines)
      .pipe(map((result) => result.map((mvp) => new ProjectMVPLine(camelcaseKeys(mvp)))));
  }

  updateProjectMVPLines(projectMVPLines: ProjectMVPLine[]) {
    return this.httpClient.patch<ProjectMVPLine[]>(`${this.apiBase}/project-mvp-line`, projectMVPLines)
      .pipe(map((result) => result.map((mvp) => new ProjectMVPLine(camelcaseKeys(mvp)))));
  }

  removeProjectMVPLines(ids: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/project-mvp-line`, { body: ids });
  }

  getProjectEstimations(filters: Filter[]) {
    let url = `${this.apiBase}/project-estimation`;
    const filter = DashboardComponent.createFilterString(filters);

    if (filter !== '') {
      url += `?filter=${filter}`;
    }

    return this.httpClient.get<ProjectEstimation[]>(url)
      .pipe(map((result) => result.map((estimation) => new ProjectEstimation(camelcaseKeys(estimation)))));
  }

  addProjectEstimations(projectEstimations: ProjectEstimation[]) {
    return this.httpClient.post<ProjectEstimation[]>(`${this.apiBase}/project-estimation`, projectEstimations)
      .pipe(map((result) => result.map((estimation) => new ProjectEstimation(camelcaseKeys(estimation)))));
  }

  updateProjectEstimations(projectEstimations: ProjectEstimation[]) {
    return this.httpClient.patch<ProjectEstimation[]>(`${this.apiBase}/project-estimation`, projectEstimations)
      .pipe(map((result) => result.map((estimation) => new ProjectEstimation(camelcaseKeys(estimation)))));
  }

  removeProjectEstimations(ids: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/project-estimation`, { body: ids });
  }

  getQuantityUnits() {
    return this.httpClient.get<QuantityUnit[]>(`${this.apiBase}/quantity-unit`)
      .pipe(map((result) => result.map((unit) => new QuantityUnit(camelcaseKeys(unit)))));
  }
  
  getLinkedQuantityUnits() {
    return this.httpClient.get<QuantityUnit[]>(`${this.apiBase}/quantity-unit/linked`)
      .pipe(map((result) => result.map((unit) => new QuantityUnit(camelcaseKeys(unit)))));
  }

  addQuantityUnits(quantityUnits: QuantityUnit[]) {
    return this.httpClient.post<QuantityUnit[]>(`${this.apiBase}/quantity-unit`, quantityUnits)
      .pipe(map((result) => result.map((unit) => new QuantityUnit(camelcaseKeys(unit)))));
  }

  updateQuantityUnits(quantityUnits: QuantityUnit[]) {
    return this.httpClient.patch<QuantityUnit[]>(`${this.apiBase}/quantity-unit`, quantityUnits)
      .pipe(map((result) => result.map((unit) => new QuantityUnit(camelcaseKeys(unit)))));
  }

  removeQuantityUnits(ids: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/quantity-unit`, { body: ids });
  }

  getTranslations(lang: string) {
    return this.httpClient.get<any>(`${this.apiBase}/user/translations/${lang}`);
  }
  
  getUsers() {
    return this.httpClient.get<GroupUser[]>(`${this.apiBase}/organization/users`)
      .pipe(map((users) => users.map((u) => new GroupUser(camelcaseKeys(u)))));
  }

  getGroups() {
    return this.httpClient.get<GroupUser[]>(`${this.apiBase}/organization/groups`)
      .pipe(map((groups) => groups.map((g) => new GroupUser(camelcaseKeys(g)))));
  }

  getStatus() {
    return this.httpClient.get<Status[]>(`${this.apiBase}/status`)
      .pipe(map((statusses) => statusses.map((s) => new Status(camelcaseKeys(s)))));
  }

  getClients() {
    return this.httpClient.get<Client[]>(`${this.apiBase}/client`)
      .pipe(map((clients) => clients.map((c) => new Client(camelcaseKeys(c)))));
  }

  getDiscounts() {
    return this.httpClient.get<Discount[]>(`${this.apiBase}/discount`)
      .pipe(map((discounts) => discounts.map((d) => new Discount(camelcaseKeys(d)))));
  }

  addDiscounts(discounts: Discount[]) {
    return this.httpClient.post<Discount[]>(`${this.apiBase}/discount`, discounts)
      .pipe(map((result) => result.map((d) => new Discount(camelcaseKeys(d)))));
  }

  updateDiscounts(discounts: Discount[]) {
    return this.httpClient.patch<Discount[]>(`${this.apiBase}/discount`, discounts)
      .pipe(map((result) => result.map((d) => new Discount(camelcaseKeys(d)))));
  }

  removeDiscounts(ids: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/discount`, { body: ids });
  }

  getConfig() {
    return this.httpClient.get<Config>(`${this.apiBase}/organization/config`)
      .pipe(map((c) => new Config(camelcaseKeys(c))));
  }

  updateConfig(config: Config) {
    return this.httpClient.post<Config>(`${this.apiBase}/organization/config`, config)
      .pipe(map((c) => new Config(camelcaseKeys(c))));
  }
}
