import { Action, createReducer, on } from '@ngrx/store';
import { Rate } from '../../models/project/rate';
import { AppState } from '../../../app.reducer';
import {
  getDefaultRatesResolved,
  removeDefaultRateResolved,
  updateDefaultRatesResolved,
  addDefaultRatesResolved,
} from './rate.actions';

export const featureSlice = 'rate';

export interface State {
  rates?: Rate[];
  rate?: Rate;
  ids?: string[];
}
const defaultState: State = {
  rates: undefined,
  rate: undefined,
  ids: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return rateReducer(state, action);
}

export const initialState: State = defaultState;

export const rateReducer = createReducer(
  initialState,
  on(getDefaultRatesResolved, (state, { rates }) => ({
    ...state,
    rates: [
      ...(state.rates ?? []).filter(
        (r) => !rates.some((p) => p.id == r.id)
      ),
      ...rates,
    ],
  })),
  on(removeDefaultRateResolved, (state, { ids }) => ({
    ...state,
    rates: state.rates?.filter((r) => !ids.includes(r.id!)),
  })),
  on(updateDefaultRatesResolved, (state, { updatedDefaultRates }) => ({
    ...state,
    rates: state.rates?.map(
      (r) =>
        updatedDefaultRates.find((u) => r.id === u.id) ?? r
    ),
  })),
  on(addDefaultRatesResolved, (state, { addedDefaultRates }) => ({
    ...state,
    rates: addedDefaultRates.concat([...(state.rates ?? [])]),
  }))
);

export const rateState = (state: AppState) => state.coreFeature.rate;
