import { BaseEntity } from 'processdelight-angular-components';

export class ProjectMVPLine extends BaseEntity {
  title!: string;
  description?: string;
  mvpId!: string;
  amount?: number;
  unitPrice?: number;
  rowNr?: number;
  skillUnit?: string;
  productUnitId?: string;
  skillId?: string;
  productId?: string;

  constructor(obj: Partial<ProjectMVPLine>) {
    super(obj);
    Object.assign(this, obj);
  }
}
