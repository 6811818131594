import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import { Product } from '../../models/project/product';
import {
  getProductsResolved,
  removeProductResolved,
  updateProductsResolved,
  addProductsResolved,
  getQuantityUnitsResolved,
  removeQuantityUnitResolved,
  addQuantityUnitsResolved,
  updateQuantityUnitsResolved,
  getLinkedQuantityUnitsResolved,
} from './product.actions';
import { QuantityUnit } from '../../models/project/quantityUnit';

export const featureSlice = 'product';

export interface State {
  products?: Product[];
  product?: Product;
  productIds?: string[];
  quantityUnits?: QuantityUnit[];
  quantityUnit?: QuantityUnit;
  quantityUnitIds?: string[];
  linkedUnits?: QuantityUnit[];
}
const defaultState: State = {
  products: undefined,
  product: undefined,
  productIds: [],
  quantityUnits: undefined,
  quantityUnit: undefined,
  quantityUnitIds: [],
  linkedUnits: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return productReducer(state, action);
}

export const initialState: State = defaultState;

export const productReducer = createReducer(
  initialState,
  on(getProductsResolved, (state, { products }) => ({
    ...state,
    products: [...products],
  })),
  on(removeProductResolved, (state, { id }) => ({
    ...state,
    products: state.products?.filter((p) => p.id !== id),
  })),
  on(updateProductsResolved, (state, { updatedProducts }) => ({
    ...state,
    products: state.products?.map(
      (p) => updatedProducts.find((u) => p.id === u.id) ?? p
    ),
  })),
  on(addProductsResolved, (state, { addedProducts }) => ({
    ...state,
    products: addedProducts.concat([...(state.products ?? [])]),
  })),
  on(getQuantityUnitsResolved, (state, { quantityUnits }) => ({
    ...state,
    quantityUnits: [...quantityUnits],
  })),
  on(removeQuantityUnitResolved, (state, { ids }) => ({
    ...state,
    quantityUnits: state.quantityUnits?.filter(
      (u) => !ids.includes(u.id!)
    ),
    products: state.products?.map((p) => ({
      ...p,
      definedUnits: p.definedUnits?.filter(
        (u) => !ids.includes(u.id!)
      ),
    })),
  })),
  on(addQuantityUnitsResolved, (state, { addedQuantityUnits }) => ({
    ...state,
    quantityUnits: addedQuantityUnits.concat([...(state.quantityUnits ?? [])]),
  })),
  on(updateQuantityUnitsResolved, (state, { updatedQuantityUnits }) => ({
    ...state,
    quantityUnits: state.quantityUnits?.map(
      (p) =>
        updatedQuantityUnits.find(
          (u) => p.id === u.id
        ) ?? p
    ),
  })),
  on(getLinkedQuantityUnitsResolved, (state, { linkedUnits }) => ({
    ...state,
    linkedUnits: [...linkedUnits],
  })),
);

export const productState = (state: AppState) => state.coreFeature.product;
