import { BaseEntity } from 'processdelight-angular-components';
import { QuantityUnit } from './quantityUnit';

export class Product extends BaseEntity {
  title!: string;
  description?: string;
  ean?: string;
  color!: string;
  isDeleted?: boolean;
  definedUnits?: QuantityUnit[];

  constructor(obj: Partial<Product>) {
    super(obj);
    Object.assign(this, obj);
  }
}
