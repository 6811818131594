import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarProjectService } from '../../services/project.service';
import {
  addProjectTypes,
  addProjectTypesResolved,
  getProjectTypes,
  getProjectTypesResolved,
  removeProjectTypes,
  removeProjectTypesResolved,
  updateProjectTypes,
  updateProjectTypesResolved,
} from './project-type.actions';
import { removeProjectEstimationParamsResolved } from '../projectEstimationParam/projectEstimationParam.actions';
import { projectTypeDeleteActionResolved } from '../project-type-line/project-type-line.actions';

@Injectable({ providedIn: 'root' })
export class ProjectTypeEffects {
  constructor(
    private actions$: Actions,
    private ishtarProjectService: IshtarProjectService
  ) {}

  getProjectTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectTypes),
      switchMap(({ callback }) =>
        this.ishtarProjectService.getProjectTypes().pipe(
          switchMap((projectTypes) =>
            of(
              getProjectTypesResolved({
                projectTypes,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addProjectTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(addProjectTypes),
      switchMap(({ projectTypes, callback, error }) =>
        this.ishtarProjectService.addProjectTypes(projectTypes).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedProjectTypes) =>
            of(addProjectTypesResolved({ addedProjectTypes }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateProjectTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProjectTypes),
      switchMap(({ projectTypes, callback, error }) =>
        this.ishtarProjectService.updateProjectTypes(projectTypes).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedProjectTypes) =>
            of(updateProjectTypesResolved({ updatedProjectTypes }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeProjectTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProjectTypes),
      switchMap(({ id, callback, error }) =>
        this.ishtarProjectService.removeProjectType(id).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((result) => {
            const actions: any[] = [
              removeProjectTypesResolved({
                deletedProjectTypeId: result.deletedProjectTypeId,
              }),
              projectTypeDeleteActionResolved({
                deletedProjectTypeId: result.deletedProjectTypeId,
              }),
            ];
            if (result.deletedEstimationParamId) {
              actions.push(
                removeProjectEstimationParamsResolved({
                  ids: [
                    result.deletedEstimationParamId,
                  ],
                })
              );
            }
            return actions;
          }),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
