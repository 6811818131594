import { BaseEntity } from 'processdelight-angular-components';

export class ProjectTypeLine extends BaseEntity {
  title!: string;
  skillId?: string;
  projectTypeId!: string;
  productId?: string;
  productUnitId?: string;
  skillUnit?: string;
  amount?: number;

  constructor(obj: Partial<ProjectTypeLine>) {
    super(obj);
    Object.assign(this, obj);
  }
}
