import { BaseEntity } from 'processdelight-angular-components';

export class ProjectEstimation extends BaseEntity {
  title!: string;
  amount?: number;
  projectId!: string;
  fixedPercentages?: string[];
  approvalStatusId?: string;
  approverId?: string;

  constructor(obj: Partial<ProjectEstimation>) {
    super(obj);
    Object.assign(this, obj);
  }
}
