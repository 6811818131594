import { BaseEntity } from 'processdelight-angular-components';

export class Discount extends BaseEntity {
  reason!: string;
  value!: number;
  percent!: boolean;

  constructor(obj: Partial<Discount>) {
    super(obj);
    Object.assign(this, obj);
  }
}
