import { BaseEntity } from 'processdelight-angular-components';

export class ProjectEstimationParam extends BaseEntity {
  title!: string;
  useStoryPoints?: boolean;
  storyPointAmount?: number;

  constructor(obj: Partial<ProjectEstimationParam>) {
    super(obj);
    Object.assign(this, obj);
  }
}
