import { Action, createReducer, on } from '@ngrx/store';
import { Skill } from '../../models/project/skill';
import { AppState } from '../../../app.reducer';
import {
  getSkillsResolved,
  removeSkillResolved,
  updateSkillsResolved,
  addSkillsResolved,
} from './skill.actions';

export const featureSlice = 'skill';

export interface State {
  skills?: Skill[];
  skill?: Skill;
  ids?: string[];
}
const defaultState: State = {
  skills: undefined,
  skill: undefined,
  ids: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return skillReducer(state, action);
}

export const initialState: State = defaultState;

export const skillReducer = createReducer(
  initialState,
  on(getSkillsResolved, (state, { skills }) => ({
    ...state,
    skills: [...skills],
  })),
  on(removeSkillResolved, (state, { id }) => ({
    ...state,
    skills: state.skills?.filter((s) => s.id !== id),
  })),
  on(updateSkillsResolved, (state, { updatedSkills }) => ({
    ...state,
    skills: state.skills?.map(
      (s) => updatedSkills.find((u) => s.id === u.id) ?? s
    ),
  })),
  on(addSkillsResolved, (state, { addedSkills }) => ({
    ...state,
    skills: addedSkills.concat([...(state.skills ?? [])]),
  }))
);

export const skillState = (state: AppState) => state.coreFeature.skill;
