import { BaseEntity } from 'processdelight-angular-components';

export class Skill extends BaseEntity {
  title!: string;
  code!: string;
  color!: string;
  billable!: boolean;
  isDeleted?: boolean;

  constructor(obj: Partial<Skill>) {
    super(obj);
    Object.assign(this, obj);
  }
}
