import { createAction, props } from '@ngrx/store';
import { ProjectMVPBlockTemplate } from '../../models/project/projectMVPBlockTemplate';
import { ActionBase } from '../action-base.interface';

export const getProjectMVPBlockTemplates = createAction(
  '[ProjectMVPBlockTemplates] Get projectMVPBlockTemplates',
  props<{
    callback?: () => void;
  }>()
);

export const getProjectMVPBlockTemplatesResolved = createAction(
  '[ProjectMVPBlockTemplates] Get projectMVPBlockTemplates resolved',
  props<{
    projectMVPBlockTemplates: ProjectMVPBlockTemplate[];
  }>()
);

export const removeProjectMVPBlockTemplates = createAction(
  '[ProjectMVPBlockTemplates] Remove projectMVPBlockTemplates',
  props<{ ids: string[] } & ActionBase<string[]>>()
);

export const removeProjectMVPBlockTemplatesResolved = createAction(
  '[ProjectMVPBlockTemplates] Remove projectMVPBlockTemplates resolved',
  props<{ ids: string[] }>()
);

export const updateProjectMVPBlockTemplates = createAction(
  '[ProjectMVPBlockTemplates] Update projectMVPBlockTemplates',
  props<
    {
      projectMVPBlockTemplates: ProjectMVPBlockTemplate[];
    } & ActionBase<ProjectMVPBlockTemplate[]>
  >()
);

export const updateProjectMVPBlockTemplatesResolved = createAction(
  '[ProjectMVPBlockTemplates] Update projectMVPBlockTemplates resolved',
  props<{ updatedProjectMVPBlockTemplates: ProjectMVPBlockTemplate[] }>()
);

export const addProjectMVPBlockTemplates = createAction(
  '[ProjectMVPBlockTemplates] Add projectMVPBlockTemplates',
  props<
    {
      projectMVPBlockTemplates: ProjectMVPBlockTemplate[];
    } & ActionBase<ProjectMVPBlockTemplate[]>
  >()
);

export const addProjectMVPBlockTemplatesResolved = createAction(
  '[ProjectMVPBlockTemplates] Add projectMVPBlockTemplates resolved',
  props<{
    addedProjectMVPBlockTemplates: ProjectMVPBlockTemplate[];
  }>()
);
